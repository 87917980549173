import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  /* margin-bottom: 25px; */
`
export const InputGroup = styled.label`
  position: relative;
  display: flex;
  align-items: center;

  & + label {
    margin-left: 15px;
  }

  cursor: pointer;
  &:last-child:before {
    display: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  span {
    display: inline-block;
    color: #fa2b56;
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
  input {
    width: 16px;
    height: 16px;
    background-color: #fa2b56;
    border-radius: 5px;
    color: #fff;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    flex-shrink: 0;
    outline: none;
  }
  input:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  input:checked:before {
    background-color: var(--orange);
  }
  @media (max-width: 825px) {
    flex-direction: row;
    align-items: start;
  }
`
export const Descricao = styled.div`
  flex-direction: column;
  p {
    color: var(--dark-text-color);
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
  @media (max-width: 825px) {
    margin-left: 15px;
  }
`
export const Titulo = styled.h1`
  color: var(--primary);
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
`
export const Error = styled.div`
  > span {
    display: inline-block;
    color: #fa2b56;
    font-size: 13px;
    font-weight: 500;
    padding-top: 7px;
  }
`
