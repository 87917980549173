import { Container, ContainerFormPerfil, Table } from './styles'

function List({ children }) {
  return (
    <Container>
      <ContainerFormPerfil>
        <Table>{children}</Table>
      </ContainerFormPerfil>
    </Container>
  )
}

export default List
