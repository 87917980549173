export const Types = {
  SIDE_NAV_EXPAND: '@sidebar/SIDE_NAV_EXPAND'
}

const INITIAL_STATE = {
  sideNavExpanded: false
}

export default function sidebar(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SIDE_NAV_EXPAND:
      return { ...state, sideNavExpanded: action.nav }
    default:
      return state
  }
}

export function sideNavExpand(nav) {
  return {
    type: Types.SIDE_NAV_EXPAND,
    nav
  }
}
