import { useState, useCallback, useEffect, useLayoutEffect } from 'react'

import { confirmAlert } from 'react-confirm-alert'

import { MdEdit, MdClose } from 'react-icons/md'

import { useSelector } from 'react-redux'

import Button from '../../../components/Buttons/Button'

import Loading from '../../../components/Loading'

import { Header } from '../../../components/Main'

import List from '../../../components/Main/List'

import { Confirm } from '../../../components/Modal'

import history from '../../../routes/history'

import api from '../../../services/api'

import removerAcentos from '../../../utils/removerAcentos'

import { ContainerFilters } from './styles'

function Aulas(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { path } = props.match

  const { searchTermValue } = useSelector(state => state.search)

  const getAulas = useCallback(async () => {
    const response = await api.get('/aulas')

    setData(() => {
      return response.data.filter(element => {
        return removerAcentos(element.nome.toLowerCase()).includes(
          searchTermValue.toLowerCase()
        )
      })
    })
  }, [searchTermValue])

  const handleClickDelete = useCallback(
    async item => {
      const { id } = item

      await api.delete(`/aulas/${id}`)

      const newData = data.filter(data => data.id !== id)

      setData(newData)
    },
    [data]
  )

  const handleDelete = useCallback(
    data => {
      confirmAlert({
        overlayClassName: 'overlay-custom-class-name',
        customUI: function confirmDelete({ onClose }) {
          return (
            <Confirm
              onClose={onClose}
              handleClickDelete={() => handleClickDelete(data)}
            />
          )
        }
      })
    },
    [handleClickDelete]
  )

  useEffect(() => {
    getAulas()
  }, [getAulas])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header title="Aulas" path={`${path}/new`} />

      <ContainerFilters>
        <Button
          type="button"
          onClick={getAulas}
          transform="true"
          title="Aulas"
          size="14"
        />

        <Button
          type="button"
          onClick={() => history.push('/sub-lessons')}
          transform="true"
          title="Sub Aulas"
          size="14"
        />
      </ContainerFilters>

      <List>
        <thead>
          <tr>
            <th>ID</th>
            <th>Módulo</th>
            <th>Nome</th>
            <th>Slug</th>
            <th>Video</th>
            <th>Data de Criação</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => (
            <tr key={data.id}>
              <td data-label="ID">
                <div>{data.id}</div>
              </td>
              <td data-label="Módulo">
                <div>{data.modulo.nome}</div>
              </td>
              <td data-label="Nome">
                <div className="produto_link">{data.nome}</div>
              </td>
              <td data-label="Slug">{data.slug}</td>
              <td data-label="Video">{data.video}</td>
              <td data-label="Data de Criação">
                <div>{data.created_at}</div>
              </td>
              <td data-label="Opções">
                <div className="container_opcoes">
                  <div
                    onClick={() => history.push(`/lessons/edit/${data.slug}`)}
                    className="opcoes"
                  >
                    <MdEdit size={21} color="var(--orange)" />
                  </div>
                  <div onClick={() => handleDelete(data)} className="opcoes">
                    <MdClose size={21} color="var(--orange)" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </List>
    </>
  )
}

export default Aulas
