import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormCursos } from '@unform/web'

import * as Yup from 'yup'

import Audio from '../../../components/Audio'

import Button from '../../../components/Buttons/Button'

import {
  Input,
  Textarea,
  Select,
  ImageInput,
  AudioInput
} from '../../../components/Form'

import { Header } from '../../../components/Main'

import Thumbnail from '../../../components/Thumbnail'

import history from '../../../routes/history'

import api from '../../../services/api'

import {
  Container,
  InputGroup,
  LabelInput,
  ContainerOtherForm,
  InputGroupOther
} from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [produtos, setProdutos] = useState({})
  const [packSelected, setPackSelected] = useState('')
  const formRef = useRef(null)
  const thumbnail = useRef(null)
  const audio = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getPacks = useCallback(async () => {
    const response = await api.get('/packs')
    if (response.data) {
      setOptions(response.data)
    }
  }, [])

  const getCurso = useCallback(async () => {
    const response = await api.get(`/produtos/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue('pack_id', 1)
      thumbnail.current.SetThumbnail(response.data.thumbnail)
      audio.current.SetAudio(response.data.audio_demo)
      setProdutos(response.data)
    }
  }, [id])

  const getSelectedOption = useCallback(() => {
    const option = options?.find(option => option.id === produtos.pack_id)

    if (option) {
      formRef.current.setFieldValue('pack_id', {
        value: option.id,
        label: option.nome
      })
    }
  }, [options, produtos])

  useEffect(() => {
    getPacks()
    if (type === 'edit') {
      getCurso()
    }
  }, [type, getCurso, getPacks])

  useEffect(() => {
    if (type === 'edit') {
      getSelectedOption()
    }
  }, [type, getSelectedOption])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  const packOptionSelected =
    packSelected === 'Modo Livre' || packSelected === 'Modo Avançado'

  async function handleSubmit(data) {
    setLoading(true)
    try {
      let schema

      if (type === 'edit') {
        schema = Yup.object().shape({
          titulo: Yup.string().required('Titulo obrigatório'),
          preco: Yup.string().required('Preço obrigatório'),
          pack_id: Yup.string().nullable().required('Opção obrigatório')
        })
      } else {
        schema = Yup.object().shape({
          titulo: Yup.string().required('Titulo obrigatório'),
          preco: Yup.string().required('Preço obrigatório'),
          image: Yup.string().required('Imagem obrigatório'),
          pack_id: Yup.string().nullable().required('Opção obrigatório')
        })
      }

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const {
        titulo,
        descricao,
        professor,
        preco,
        video_fa,
        video_sol,
        pack_id,
        image,
        audio_demo
      } = data

      const produto = new FormData()

      produto.append('image', image)
      produto.append('audio_demo', audio_demo || '')
      produto.append('titulo', titulo)
      produto.append('descricao', descricao)
      produto.append('professor', professor)
      produto.append('preco', preco)
      produto.append('video_fa', video_fa)
      produto.append('video_sol', video_sol)
      produto.append('pack_id', pack_id)

      if (type === 'edit') {
        const response = await api.put(`produtos/${produtos.id}`, produto)

        thumbnail.current.SetThumbnail(response.data.thumbnail)
        audio.current.SetAudio(response.data.audio_demo)

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/produtos', produto)

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormCursos ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Titulo</LabelInput>
            <Input type="text" name="titulo" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Descrição</LabelInput>
            <Textarea name="descricao" rows="8" cols="50"></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Professor</LabelInput>
            <Input type="text" name="professor" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Preço</LabelInput>
            <Input type="text" name="preco" />
          </InputGroup>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Video Fa</LabelInput>
              <Input type="text" name="video_fa" />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Video Sol</LabelInput>
              <Input type="text" name="video_sol" />
            </InputGroupOther>
          </ContainerOtherForm>

          <InputGroup>
            <LabelInput>Modos de aprendizagem</LabelInput>
            <Select
              name="pack_id"
              options={options.map(option => {
                return { value: option.id, label: option.nome }
              })}
              placeholder="Selecione modo de aprendizagem"
              styles={customStyles}
              onChange={({ label }) => setPackSelected(label)}
            />
          </InputGroup>

          <Audio ref={audio} />

          {packOptionSelected && (
            <AudioInput name="audio_demo" accept="audio/*" />
          )}

          <Thumbnail ref={thumbnail} />

          <ImageInput name="image" accept="image/*" />

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormCursos>
      </Container>
    </>
  )
}

export default Form
