import {
  FaTachometerAlt,
  FaUsers,
  FaGraduationCap,
  FaBuffer,
  FaBookReader,
  FaBell,
  FaCreditCard,
  FaUserFriends,
  FaClipboardList
} from 'react-icons/fa'

import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import { Container } from './styles'

function Sidebar() {
  const { sideNavExpanded } = useSelector(state => state.sidebar)

  return (
    <Container sideNavExpanded={sideNavExpanded}>
      <ul>
        <div className="sidebar_heading">Geral</div>
        <li>
          <Link to="/dashboard">
            <FaTachometerAlt size={21} color="var(--orange)" />
            <div>Dashboard</div>
          </Link>
        </li>
        <li>
          <Link to="/users">
            <FaUsers size={21} color="var(--orange)" />
            <div>Utilizadores</div>
          </Link>
        </li>
        <li>
          <Link to="/compras">
            <FaClipboardList size={21} color="var(--orange)" />
            <div>Compras</div>
          </Link>
        </li>
        <li>
          <Link to="/notifications">
            <FaBell size={21} color="var(--orange)" />
            <div>Notificações</div>
          </Link>
        </li>
        <div className="sidebar_heading">Conteúdo</div>
        <li>
          <Link to="/packs">
            <FaUsers size={21} color="var(--orange)" />
            <div>Modos de aprendizagem</div>
          </Link>
        </li>
        <li>
          <Link to="/cursos">
            <FaGraduationCap size={21} color="var(--orange)" />
            <div>Cursos</div>
          </Link>
        </li>
        <li>
          <Link to="/modules">
            <FaBuffer size={21} color="var(--orange)" />
            <div>Módulos</div>
          </Link>
        </li>
        <li>
          <Link to="/lessons">
            <FaBookReader size={21} color="var(--orange)" />
            <div>Aulas</div>
          </Link>
        </li>
        {/* <li>
          <Link to="/videos">
            <FaPlayCircle size={21} color="var(--orange)" />
            <div>Videos</div>
          </Link>
        </li> */}
        <li>
          <Link to="/metodos">
            <FaCreditCard size={21} color="var(--orange)" />
            <div>Métodos</div>
          </Link>
        </li>
        <div className="sidebar_heading">Administração</div>
        <li>
          <Link to="/permissoes">
            <FaUserFriends size={21} color="var(--orange)" />
            <div>Grupo de permissões</div>
          </Link>
        </li>
      </ul>
    </Container>
  )
}

export default Sidebar
