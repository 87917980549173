import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { MdEdit } from 'react-icons/md'

import Button from '../../../components/Buttons/Button'

import Loading from '../../../components/Loading'

import { Header } from '../../../components/Main'

import List from '../../../components/Main/List'

import history from '../../../routes/history'

import api from '../../../services/api'

import { ContainerFilters } from './styles'

function Permissoes(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { path } = props.match

  const getPermissoes = useCallback(async () => {
    const response = await api.get('/permissions')

    if (response.data) {
      setData(response.data)
    }
  }, [])

  useEffect(() => {
    getPermissoes()
  }, [getPermissoes])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header title="Grupo de permissões" path={`${path}/new`} />

      <ContainerFilters>
        <Button
          type="button"
          onClick={getPermissoes}
          transform="true"
          title="Permissões"
          size="14"
        />
        <Button
          type="button"
          onClick={() => history.push('/roles')}
          transform="true"
          title="Roles"
          size="14"
        />
      </ContainerFilters>

      <List>
        <thead>
          <tr>
            <th>ID</th>
            <th>Slug</th>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Data de Criação</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => (
            <tr key={data.id}>
              <td data-label="ID">
                <div>{data.id}</div>
              </td>
              <td data-label="Slug">
                <div className="produto_link">{data.slug}</div>
              </td>
              <td data-label="Nome">
                <div>{data.name}</div>
              </td>
              <td data-label="Descrição">
                <div style={{ maxWidth: '30ch' }}>
                  {data.description.length > 50
                    ? `${data.description.substring(0, 50)}...`
                    : data.description}
                </div>
              </td>
              <td data-label="Data de Criação">
                <div>{data.created_at}</div>
              </td>
              <td data-label="Opções">
                <div
                  onClick={() => history.push(`/permissoes/edit/${data.id}`)}
                  className="opcoes"
                >
                  <MdEdit size={21} color="var(--orange)" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </List>
    </>
  )
}

export default Permissoes
