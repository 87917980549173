import { useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useDispatch } from 'react-redux'

import { toast } from 'react-toastify'

import { Form } from '@unform/web'

import * as Yup from 'yup'

import LogoImage from '../../assets/logo_header.png'

import Button from '../../components/Buttons/Button'

import { Input } from '../../components/Form'

import api from '../../services/api'

import { setToken } from '../../store/ducks/auth'

import { Container, Logo, LoginContainer, ContainerForm } from './styles'

export default function Login() {
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const dispatch = useDispatch()

  async function handleSignIn(data, { reset }) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Email inválido')
          .required('Email obrigatório'),
        password: Yup.string().required('Password obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { email, password } = data

      const response = await api.post('admin/sessions', { email, password })
      setLoading(false)

      dispatch(setToken(response.data.token))

      reset()
    } catch (err) {
      setLoading(false)

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
        return
      }

      if (err.response.data.user) {
        toast.error(err.response.data.user.errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else if (err.response.data.roles) {
        toast.error(err.response.data.roles.errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error('Dados incorrectos!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  return (
    <Container>
      <LoginContainer>
        <Logo src={LogoImage} />
        <ContainerForm>
          <Form ref={formRef} onSubmit={handleSignIn}>
            <div className="form-group">
              <Input type="text" name="email" placeholder="Email" />
            </div>
            <div className="form-group">
              <Input type="password" name="password" placeholder="Senha" />
            </div>

            <Button
              type="submit"
              transform="true"
              title="Entrar"
              loading={
                loading && (
                  <ReactLoading
                    type="spin"
                    color="#fff"
                    height={20}
                    width={20}
                  />
                )
              }
            />
          </Form>
        </ContainerForm>
      </LoginContainer>
    </Container>
  )
}
