import { createStore, applyMiddleware } from 'redux'

import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage'

import createSagaMiddleware from 'redux-saga'

import rootReducer from './ducks'

import rootSaga from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  timeout: null,
  whitelist: ['cart', 'checkout']
}

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware))

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }
