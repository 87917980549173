import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormUsers } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import { Input, Radio, Select, Textarea } from '../../../components/Form'

import { Header } from '../../../components/Main'

import history from '../../../routes/history'

import api from '../../../services/api'

import {
  Container,
  InputGroup,
  LabelInput,
  ContainerOtherForm,
  InputGroupOther
} from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [roles, setRoles] = useState([])
  const formRef = useRef()
  const { id } = useParams()

  const { type, formTitle } = props

  const getUser = useCallback(async () => {
    const response = await api.get(`/users/${id}`)

    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue('activo', String(response.data.activo))
      setUser(response.data)
    }
  }, [id])

  const getRoles = useCallback(async () => {
    const response = await api.get('/roles')

    if (response.data) {
      setRoles(response.data)
    }
  }, [])

  const getSelectedOption = useCallback(() => {
    const option = user.roles?.find(role => role.pivot.user_id === user.id)

    if (option) {
      formRef.current.setFieldValue('role', {
        value: option.id,
        label: option.name
      })
    }
  }, [user])

  useEffect(() => {
    getRoles()
    if (type === 'edit') {
      getUser()
    }
  }, [type, getUser, getRoles])

  useEffect(() => {
    if (type === 'edit') {
      getSelectedOption()
    }
  }, [type, getSelectedOption])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  async function handleSubmit(data) {
    setLoading(true)
    try {
      let schema

      if (type === 'edit') {
        schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          role: Yup.number().nullable().required('Opção obrigatório'),
          activo: Yup.string().nullable().required('Opção obrigatório')
        })
      } else {
        schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          password: Yup.string().required('Password obrigatório'),
          confirm_password: Yup.string()
            .required('Confirmar password obrigatório')
            .oneOf([Yup.ref('password'), null], 'Passwords não coincidem'),
          role: Yup.number().nullable().required('Opção obrigatório'),
          activo: Yup.string().nullable().required('Opção obrigatório')
        })
      }

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const {
        nome,
        morada,
        email,
        codigo_postal,
        localidade,
        nif,
        telefone,
        password,
        role,
        activo
      } = data

      if (type === 'edit') {
        const response = await api.put(`users/${user.id}`, {
          nome,
          morada,
          email,
          codigo_postal,
          localidade,
          nif,
          telefone,
          password,
          role,
          activo
        })

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/users', {
          nome,
          morada,
          email,
          codigo_postal,
          localidade,
          nif,
          telefone,
          password,
          role,
          activo
        })

        setLoading(false)

        toast.success('Registo criada com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormUsers ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Nome completo</LabelInput>
            <Input type="text" name="nome" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Morada</LabelInput>
            <Textarea name="morada" rows="8" cols="50"></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Email</LabelInput>
            <Input type="text" name="email" />
          </InputGroup>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Codigo-Postal</LabelInput>
              <Input type="text" name="codigo_postal" />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Localidade</LabelInput>
              <Input type="text" name="localidade" />
            </InputGroupOther>
          </ContainerOtherForm>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Número de Identificação Fiscal</LabelInput>
              <Input type="text" name="nif" />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Telefone</LabelInput>
              <Input type="text" name="telefone" />
            </InputGroupOther>
          </ContainerOtherForm>
          <ContainerOtherForm>
            <InputGroupOther>
              <LabelInput>Password</LabelInput>
              <Input type="password" name="password" />
            </InputGroupOther>
            <InputGroupOther>
              <LabelInput>Confirmar Password</LabelInput>
              <Input type="password" name="confirm_password" />
            </InputGroupOther>
          </ContainerOtherForm>

          <InputGroup>
            <LabelInput>Tipo de Acesso</LabelInput>
            <Select
              name="role"
              options={roles.map(role => {
                return { value: role.id, label: role.name }
              })}
              placeholder="Selecione o tipo de acesso"
              styles={customStyles}
            />
          </InputGroup>

          <Radio
            name="activo"
            options={[
              { id: 1, label: 'Activo' },
              { id: 0, label: 'Inactivo' }
            ]}
          />

          <Button
            width={100}
            height={35}
            margin="25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormUsers>
      </Container>
    </>
  )
}

export default Form
