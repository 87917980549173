import { useCallback } from 'react'

import { FaSearch, FaPlus, FaArrowLeft } from 'react-icons/fa'

import { useDispatch, useSelector } from 'react-redux'

import { Form } from '@unform/web'

import history from '../../../routes/history'

import { searchTerm } from '../../../store/ducks/search'

import Button from '../../Buttons/Button'

import { Input } from '../../Form'

import { Container, Title, ContainerBack, Options } from './styles'

function Header({ title, options, path, newButton }) {
  const dispatch = useDispatch()

  const { searchTermValue } = useSelector(state => state.search)

  const handleBack = useCallback(() => {
    history.goBack()
  }, [])

  const handleChange = useCallback(
    event => {
      dispatch(searchTerm(event.target.value))
    },
    [dispatch]
  )

  return (
    <Container options={options}>
      <Title>{title}</Title>
      <ContainerBack onClick={handleBack} options={options}>
        <FaArrowLeft size={15} color="var(--primary)" />
        <span>Voltar</span>
      </ContainerBack>
      <Options options={options}>
        <Form>
          <Input
            type="text"
            name="pesquisa"
            value={searchTermValue}
            onChange={handleChange}
            placeholder="Pesquisar..."
          />
          <div className="buttens_header_mobile">
            <Button
              width="50"
              height="45"
              type="submit"
              transform="true"
              icon={<FaSearch size={15} color="var(--primary)" />}
            />
            {newButton === false ? null : (
              <Button
                width="100"
                height="45"
                type="button"
                transform="true"
                title="Novo"
                size="14"
                onClick={() => history.push(path)}
                icon={<FaPlus size={12} color="var(--primary)" />}
              />
            )}
          </div>
        </Form>
      </Options>
    </Container>
  )
}

export default Header
