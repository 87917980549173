import { combineReducers } from 'redux'

import auth from '../ducks/auth'

import search from '../ducks/search'

import sidebar from '../ducks/sidebar'

export default combineReducers({
  auth,
  sidebar,
  search
})
