import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import { Input, Select, Textarea } from '../../../components/Form'

import { Header } from '../../../components/Main'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [aula, setAula] = useState({})
  const [modulos, setModulos] = useState([])
  const formRef = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getAula = useCallback(async () => {
    const response = await api.get(`/aulas/${id}`)

    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue('pago', String(response.data.pago))
      setAula(response.data)
    }
  }, [id])

  const getModulos = useCallback(async () => {
    const response = await api.get('/modulos')

    setModulos(response.data)
  }, [])

  const getSelectedOption = useCallback(() => {
    const option = modulos.find(modulo => modulo.id === aula.modulo_id)

    if (option) {
      formRef.current.setFieldValue('modulo_id', {
        value: option.id,
        label: option.nome
      })
    }
  }, [modulos, aula])

  useEffect(() => {
    getModulos()
    if (type === 'edit') {
      getAula()
    }
  }, [type, getAula, getModulos])

  useEffect(() => {
    if (type === 'edit') {
      getSelectedOption()
    }
  }, [type, getSelectedOption])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        modulo_id: Yup.string().nullable().required('Opção obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { nome, descricao, video, modulo_id } = data

      if (type === 'edit') {
        const response = await api.put(`aulas/${aula.id}`, {
          nome,
          descricao,
          video,
          modulo_id
        })

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/aulas', {
          nome,
          descricao,
          video,
          modulo_id
        })

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Nome</LabelInput>
            <Input type="text" name="nome" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Descrição</LabelInput>
            <Textarea name="descricao" rows="8" cols="50"></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Video</LabelInput>
            <Input name="video" rows="8" cols="50"></Input>
          </InputGroup>

          <InputGroup>
            <LabelInput>Módulo</LabelInput>
            <Select
              name="modulo_id"
              options={modulos.map(modulo => {
                return { value: modulo.id, label: modulo.nome }
              })}
              placeholder="Selecione o módulo"
              styles={customStyles}
            />
          </InputGroup>

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
