import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  input {
    flex-shrink: 0;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    appearance: none;
    padding: 0px;
    background: var(--dark-text-color);
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    overflow: hidden;

    &:checked {
      background: var(--orange);
    }

    &:focus {
      border-color: var(--dark-text-color) 1px solid;
    }

    &::after {
      content: '';
      position: absolute;
      width: 4px;
      height: 7px;
      border-width: 0px 2px 2px 0px;
      border-right-style: solid;
      border-bottom-style: solid;
      border-right-color: var(--primary);
      border-bottom-color: var(--primary);
      border-image: initial;
      border-top-style: initial;
      border-top-color: initial;
      border-left-style: initial;
      border-left-color: initial;
      left: 5px;
      top: -15px;
      transform: rotate(43deg);
      opacity: 0;
      transition: all 0.2s ease-in 0s;
    }
    &:checked::after {
      top: 2px;
      opacity: 1;
    }
  }
  > label {
    order: 1;
    color: var(--primary);
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    span {
      cursor: pointer;
      color: var(--orange);
    }
  }
`
