import React from 'react'

import { Container } from './styles'

function Confirm({ onClose, handleClickDelete }) {
  return (
    <Container>
      <div className="custom-ui">
        <h1>Tem a certeza?</h1>
        <p>Deseja realmente eliminar este registo?</p>
        <button onClick={onClose}>Não</button>
        <button
          onClick={() => {
            handleClickDelete()
            onClose()
          }}
        >
          Apagar
        </button>
      </div>
    </Container>
  )
}

export default Confirm
