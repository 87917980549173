import { useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import LogoImage from '../assets/logo_header.png'

import Header from '../components/Header'

import Sidebar from '../components/Sidebar'

import { Container, ContainerLogo, Logo, Main } from './styles'

function MainAdmin({ children }) {
  const { sideNavExpanded } = useSelector(state => state.sidebar)

  return (
    <Container sideNavExpanded={sideNavExpanded}>
      <ContainerLogo sideNavExpanded={sideNavExpanded}>
        <Link to="/dashboard">
          <Logo src={LogoImage} />
        </Link>
      </ContainerLogo>

      <Header />
      <Sidebar />
      <Main>{children}</Main>
    </Container>
  )
}

export default MainAdmin
