import { Switch } from 'react-router-dom'

import MainAdmin from '../Layout'

import { Aulas, Form as FormAulas } from '../pages/Aulas'

import { Compras, Form as FormCompras } from '../pages/Compras'

import { Cursos, Form as FormCursos } from '../pages/Cursos'

import Dashboard from '../pages/Dashboard'

import Login from '../pages/Login'

import { Metodos, Form as FormMetodos } from '../pages/Metodos'

import { Modulos, Form as FormModulos } from '../pages/Modulos'

import { Notificacoes, Form as FormNotificacoes } from '../pages/Notificacoes'

import { Packs, Form as FormPacks } from '../pages/Packs'

import { Permissoes, Form as FormPermissoes } from '../pages/Permissoes'

import { Roles, Form as FormRoles } from '../pages/Roles'

import { SubAulas, Form as FormSubAulas } from '../pages/SubAulas'

import { Users, Form as FormUsers } from '../pages/Users'

import Route from './Route'

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <MainAdmin>
        <Switch>
          <Route path="/dashboard" isPrivate component={Dashboard} />
          <Route path="/users" exact isPrivate component={Users} />
          <Route
            path="/users/edit/:id"
            isPrivate
            component={props => (
              <FormUsers type="edit" formTitle="Actualizar User" {...props} />
            )}
          />
          <Route
            path="/users/new"
            isPrivate
            component={props => (
              <FormUsers type="new" formTitle="Novo User" {...props} />
            )}
          />
          <Route path="/compras" exact isPrivate component={Compras} />
          <Route
            path="/compras/edit/:id"
            isPrivate
            component={props => (
              <FormCompras
                type="edit"
                formTitle="Actualizar Compra"
                {...props}
              />
            )}
          />

          <Route
            path="/users/edit/:id"
            isPrivate
            component={props => (
              <FormUsers type="edit" formTitle="Actualizar User" {...props} />
            )}
          />
          <Route
            path="/users/new"
            isPrivate
            component={props => (
              <FormUsers type="new" formTitle="Novo User" {...props} />
            )}
          />

          <Route
            path="/notifications"
            exact
            isPrivate
            component={Notificacoes}
          />
          {/* <Route
            path="/notifications/edit/:id"
            isPrivate
            component={props => (
              <FormNotificacoes
                type="edit"
                formTitle="Actualizar notificação"
                {...props}
              />
            )}
          /> */}
          <Route
            path="/notifications/new"
            isPrivate
            component={props => (
              <FormNotificacoes
                type="new"
                formTitle="Nova notificação"
                {...props}
              />
            )}
          />

          <Route path="/packs" exact isPrivate component={Packs} />
          <Route
            path="/packs/edit/:id"
            isPrivate
            component={props => (
              <FormPacks type="edit" formTitle="Actualizar Pack" {...props} />
            )}
          />
          <Route
            path="/packs/new"
            isPrivate
            component={props => (
              <FormPacks type="new" formTitle="Novo Pack" {...props} />
            )}
          />
          <Route path="/cursos" exact isPrivate component={Cursos} />
          <Route
            path="/cursos/edit/:id"
            isPrivate
            component={props => (
              <FormCursos type="edit" formTitle="Actualizar Curso" {...props} />
            )}
          />
          <Route
            path="/cursos/new"
            isPrivate
            component={props => (
              <FormCursos type="new" formTitle="Novo Curso" {...props} />
            )}
          />
          <Route path="/modules" exact isPrivate component={Modulos} />
          <Route
            path="/modules/edit/:id"
            isPrivate
            component={props => (
              <FormModulos
                type="edit"
                formTitle="Actualizar Módulo"
                {...props}
              />
            )}
          />
          <Route
            path="/modules/new"
            isPrivate
            component={props => (
              <FormModulos type="new" formTitle="Novo Módulo" {...props} />
            )}
          />
          <Route path="/lessons" exact isPrivate component={Aulas} />
          <Route
            path="/lessons/edit/:id"
            isPrivate
            component={props => (
              <FormAulas type="edit" formTitle="Actualizar Aula" {...props} />
            )}
          />
          <Route
            path="/lessons/new"
            isPrivate
            component={props => (
              <FormAulas type="new" formTitle="Nova Aula" {...props} />
            )}
          />

          <Route path="/sub-lessons" exact isPrivate component={SubAulas} />
          <Route
            path="/sub-lessons/edit/:id"
            isPrivate
            component={props => (
              <FormSubAulas
                type="edit"
                formTitle="Actualizar SubAula"
                {...props}
              />
            )}
          />
          <Route
            path="/sub-lessons/new"
            isPrivate
            component={props => (
              <FormSubAulas type="new" formTitle="Nova SubAula" {...props} />
            )}
          />

          <Route path="/metodos" exact isPrivate component={Metodos} />
          <Route
            path="/metodos/edit/:id"
            isPrivate
            component={props => (
              <FormMetodos
                type="edit"
                formTitle="Actualizar métodos de pagamento"
                {...props}
              />
            )}
          />
          <Route
            path="/metodos/new"
            isPrivate
            component={props => (
              <FormMetodos
                type="new"
                formTitle="Nova métodos de pagamento"
                {...props}
              />
            )}
          />
          <Route path="/permissoes" exact isPrivate component={Permissoes} />
          <Route
            path="/permissoes/edit/:id"
            isPrivate
            component={props => (
              <FormPermissoes
                type="edit"
                formTitle="Actualizar permissão"
                {...props}
              />
            )}
          />
          <Route
            path="/permissoes/new"
            isPrivate
            component={props => (
              <FormPermissoes
                type="new"
                formTitle="Nova permissão"
                {...props}
              />
            )}
          />

          <Route path="/roles" exact isPrivate component={Roles} />
          <Route
            path="/roles/edit/:id"
            isPrivate
            component={props => (
              <FormRoles type="edit" formTitle="Actualizar Role" {...props} />
            )}
          />
          <Route
            path="/roles/new"
            isPrivate
            component={props => (
              <FormRoles type="new" formTitle="Nova Role" {...props} />
            )}
          />
        </Switch>
      </MainAdmin>
    </Switch>
  )
}
