import styled, { keyframes } from 'styled-components'

const MoveUpDown = keyframes`
  0% { transform: translateY(0); }
  100% { transform: translateY(-15px); }
`

export const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > div {
    -webkit-animation: ${MoveUpDown} 1s infinite alternate;
    animation: ${MoveUpDown} 1s infinite alternate;
  }
`
