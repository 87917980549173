import React from 'react'

import { Container, Button1 } from './styles'

function Button({
  title,
  radius,
  width,
  height,
  margin,
  transform,
  icon,
  background,
  border,
  size,
  color,
  loading,
  ...rest
}) {
  return (
    <Container
      radius={radius}
      width={width}
      height={height}
      margin={margin}
      background={background}
      border={border}
      size={size}
    >
      {loading ? (
        <Button1 transform={transform} color={color} {...rest}>
          {loading}
        </Button1>
      ) : (
        <Button1 transform={transform} size={size} color={color} {...rest}>
          {!title ? (
            <div className="icon">{icon}</div>
          ) : (
            <div className="icon_before_icon">
              {icon} {title}
            </div>
          )}
        </Button1>
      )}
    </Container>
  )
}

export default Button
