import styled from 'styled-components'

export const Container = styled.input`
  width: 200px;
  height: 45px;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  color: var(--black);
  &::placeholder {
    color: var(--black);
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
