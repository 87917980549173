import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import { parseISO } from 'date-fns'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import { Input, Radio, Textarea } from '../../../components/Form'

import { Header } from '../../../components/Main'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [compra, setCompra] = useState({})
  const formRef = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getCompra = useCallback(async () => {
    const response = await api.get(`/compras-list/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue(
        'created_at',
        Intl.DateTimeFormat('pt-PT', {
          year: 'numeric',
          month: 'long',
          day: '2-digit'
        }).format(parseISO(response.data.created_at))
      )
      formRef.current.setFieldValue(
        'total',
        Intl.NumberFormat('pt-PT', {
          style: 'currency',
          currency: 'EUR'
        }).format(response.data.total)
      )

      formRef.current.setData({
        produtos: response.data.item
          .map(item => `${item.produto.titulo}\n`)
          .join('')
      })

      formRef.current.setFieldValue('metodo', response.data.payment.titulo)

      formRef.current.setFieldValue('aluno', response.data.user.nome)

      formRef.current.setFieldValue('status', String(response.data.status))

      setCompra(response.data)
    }
  }, [id])

  useEffect(() => {
    if (type === 'edit') {
      getCompra()
    }
  }, [type, getCompra])

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        status: Yup.string().nullable().required('Opção obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { status } = data

      if (type === 'edit') {
        const response = await api.put(`compras/${compra.id}`, {
          status
        })

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
          history.goBack()
        }
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Nº Pedido</LabelInput>
            <Input type="text" name="id" disabled />
          </InputGroup>
          <InputGroup>
            <LabelInput>Aluno</LabelInput>
            <Input type="text" name="aluno" disabled />
          </InputGroup>
          <InputGroup>
            <LabelInput>Data de Compra</LabelInput>
            <Input type="text" name="created_at" disabled />
          </InputGroup>
          <InputGroup>
            <LabelInput>Produtos</LabelInput>
            <Textarea name="produtos" rows="8" cols="50" disabled></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Método de Pagamento</LabelInput>
            <Input type="text" name="metodo" disabled />
          </InputGroup>
          <InputGroup>
            <LabelInput>Total</LabelInput>
            <Input type="text" name="total" disabled />
          </InputGroup>

          <LabelInput>Pago</LabelInput>
          <Radio
            name="status"
            options={[
              { id: 1, label: 'Sim' },
              { id: 0, label: 'Não' }
            ]}
          />

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
