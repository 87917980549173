import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import {
  Input,
  Radio,
  Textarea,
  Select,
  ImageInput
} from '../../../components/Form'

import { Header } from '../../../components/Main'

import Thumbnail from '../../../components/Thumbnail'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [packs, setPacks] = useState({})
  const formRef = useRef(null)
  const thumbnail = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getPack = useCallback(async () => {
    const response = await api.get(`/packs/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue('tipo', {
        label: response.data.tipo,
        value: response.data.tipo
      })
      formRef.current.setFieldValue('active', String(response.data.active))

      thumbnail.current.SetThumbnail(response.data.thumbnail)

      setPacks(response.data)
    }
  }, [id])

  useEffect(() => {
    if (type === 'edit') {
      getPack()
    }
  }, [type, getPack])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  async function handleSubmit(data) {
    setLoading(true)
    try {
      let schema

      if (type === 'edit') {
        schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          tipo: Yup.string().required('Tipo obrigatório'),
          active: Yup.string().nullable().required('Opção obrigatório')
        })
      } else {
        schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          tipo: Yup.string().required('Tipo obrigatório'),
          image: Yup.string().required('Imagem obrigatório'),
          active: Yup.string().nullable().required('Opção obrigatório')
        })
      }

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { nome, descricao, tipo, active, image } = data

      const pack = new FormData()

      pack.append('image', image)
      pack.append('nome', nome)
      pack.append('descricao', descricao)
      pack.append('tipo', tipo)
      pack.append('active', active)

      if (type === 'edit') {
        const response = await api.put(`packs/${packs.id}`, pack)

        thumbnail.current.SetThumbnail(response.data.thumbnail)

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/packs', pack)

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Nome</LabelInput>
            <Input type="text" name="nome" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Descrição</LabelInput>
            <Textarea name="descricao" rows="8" cols="50"></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Tipo</LabelInput>
            <Select
              name="tipo"
              options={[
                { value: 'cursos', label: 'cursos' },
                { value: 'videos', label: 'videos' }
              ]}
              placeholder="Selecione o tipo"
              styles={customStyles}
            />
          </InputGroup>

          <Thumbnail ref={thumbnail} />

          <ImageInput name="image" accept="image/*" />

          <Radio
            name="active"
            options={[
              { id: 1, label: 'Activo' },
              { id: 0, label: 'Inactivo' }
            ]}
          />

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
