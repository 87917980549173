import logo from '../../assets/logo.png'

import { LoadingContainer } from './styles'

function Loading() {
  return (
    <LoadingContainer>
      <div>
        <img src={logo} alt="" />
      </div>
    </LoadingContainer>
  )
}

export default Loading
