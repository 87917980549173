import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react'

import { Container } from './styles'

function Thumbnail({ source: afwf }, ref) {
  const [source, setSource] = useState('')

  const SetThumbnail = useCallback(source => {
    setSource(source)
  }, [])

  useImperativeHandle(ref, () => ({
    SetThumbnail
  }))

  return (
    <>
      {source && (
        <Container>
          <img src={source} alt="" />
        </Container>
      )}
    </>
  )
}

export default forwardRef(Thumbnail)
