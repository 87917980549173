import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  margin-bottom: 30px;
  h1 {
    font-size: 25px;
    color: var(--primary);
    font-weight: 700;
    margin-top: 5px;
  }
`

export const ContainerCounts = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 825px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
  }
`
export const Count = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  > h1 {
    font-size: 20px;
    color: var(--primary);
    font-weight: 700;
    margin-top: 20px;
  }

  > span {
    font-size: 30px;
    font-weight: 400;
    color: var(--primary);
    margin-top: 20px;
  }
`

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 30px 20px 30px 20px;
  background-color: var(--box-background);
`
