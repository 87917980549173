import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { confirmAlert } from 'react-confirm-alert'

import { MdClose } from 'react-icons/md'

import Loading from '../../../components/Loading'

import { Header } from '../../../components/Main'

import List from '../../../components/Main/List'

import { Confirm } from '../../../components/Modal'

// import history from '../../../routes/history'

import api from '../../../services/api'

function Notificacoes(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { path } = props.match

  const getNotificacoes = useCallback(async () => {
    const response = await api.get('/notifications-list')

    if (response.data) {
      setData(response.data)
    }
  }, [])

  useEffect(() => {
    getNotificacoes()
  }, [getNotificacoes])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  const handleClickDelete = useCallback(
    async item => {
      const { id } = item

      await api.delete(`/notifications/${id}`)

      const newData = data.filter(data => data.id !== id)

      setData(newData)
    },
    [data]
  )

  const handleDelete = useCallback(
    data => {
      confirmAlert({
        overlayClassName: 'overlay-custom-class-name',
        customUI: function confirmDelete({ onClose }) {
          return (
            <Confirm
              onClose={onClose}
              handleClickDelete={() => handleClickDelete(data)}
            />
          )
        }
      })
    },
    [handleClickDelete]
  )

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header title="Notificações" path={`${path}/new`} />
      <List>
        <thead>
          <tr>
            <th>ID</th>
            <th>Users</th>
            <th>Mensagem</th>
            <th>Data de Criação</th>
            <th>Lida</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => (
            <tr key={data.id}>
              <td data-label="ID">
                <div>{data.id}</div>
              </td>
              <td data-label="Users">
                {data.notification_user.slice(0, 5).map(({ user }) => (
                  <div key={user.id} className="produto_link">
                    {user.nome}
                  </div>
                ))}
              </td>
              <td data-label="Mensagem">
                <div style={{ maxWidth: '30ch' }}>
                  {data.mensagem.length > 50
                    ? `${data.mensagem.substring(0, 50)}...`
                    : data.mensagem}
                </div>
              </td>
              <td data-label="Data de Criação">
                <div>{data.created_at}</div>
              </td>
              <td data-label="Lida">
                <div className={`${data.read ? 'active' : 'inactive'}`}>
                  {data.read ? 'Vista' : 'Não Vista'}
                </div>
              </td>
              <td data-label="Opções">
                <div className="container_opcoes">
                  {/* <div
                    onClick={() =>
                      history.push(`/notifications/edit/${data.id}`)
                    }
                    className="opcoes"
                  >
                    <MdEdit size={21} color="var(--orange)" />
                  </div> */}
                  <div onClick={() => handleDelete(data)} className="opcoes">
                    <MdClose size={21} color="var(--orange)" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </List>
    </>
  )
}

export default Notificacoes
