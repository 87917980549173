import { useState, useCallback, useEffect, useLayoutEffect } from 'react'

import {
  FaUserFriends,
  FaClipboardList,
  FaGraduationCap,
  FaHandHoldingUsd
} from 'react-icons/fa'

import Loading from '../../components/Loading'

import api from '../../services/api'

import { Title, ContainerCounts, Count } from './styles'

function Dashboard() {
  const [users, setUsers] = useState([])
  const [compras, setCompras] = useState([])
  const [produtos, setProdutos] = useState([])
  const [loading, setLoading] = useState(true)

  const getUsers = useCallback(async () => {
    const response = await api.get('/users')

    setUsers(response.data)
  }, [])

  const getCompras = useCallback(async () => {
    const response = await api.get('/compras-list')

    setCompras(response.data)
  }, [])

  const getProdutos = useCallback(async () => {
    const response = await api.get('/produtos-list')

    setProdutos(response.data)
  }, [])

  useEffect(() => {
    getProdutos()
  }, [getProdutos])

  useEffect(() => {
    getCompras()
  }, [getCompras])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <Title>
        <h1>Dashboard</h1>
      </Title>

      <ContainerCounts>
        <Count>
          <FaUserFriends size={30} color="var(--orange)" />
          <h1>Alunos</h1>
          <span>{users.length}</span>
        </Count>
        <Count>
          <FaClipboardList size={30} color="var(--orange)" />
          <h1>Compras</h1>
          <span>{compras.length}</span>
        </Count>
        <Count>
          <FaGraduationCap size={30} color="var(--orange)" />
          <h1>Produtos</h1>
          <span>{produtos.length}</span>
        </Count>
        <Count>
          <FaHandHoldingUsd size={30} color="var(--orange)" />
          <h1>Ganho</h1>
          <span>
            {Intl.NumberFormat('pt-PT', {
              style: 'currency',
              currency: 'EUR'
            }).format(
              compras
                .filter(({ status }) => status === 1)
                .reduce((sum, compra) => sum + +compra.total, 0)
            )}
          </span>
        </Count>
      </ContainerCounts>
    </>
  )
}

export default Dashboard
