import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  display: grid;
  height: 100vh;

  grid-template-rows: 70px 1fr;
  grid-template-columns: ${props =>
    props.sideNavExpanded ? '60px 1fr' : '300px 1fr'};
  grid-template-areas: ${props =>
    props.sideNavExpanded
      ? css`
          'header header'
          'nav  main'
        `
      : css`
          'logo header'
          'nav  main'
        `};

  @media (max-width: 1120px) {
    grid-template-columns: 60px 1fr;
    grid-template-areas:
      'header header'
      'nav  main';
  }
`

export const Main = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: main;
  padding: 20px 20px 20px 20px;

  -webkit-overflow-scrolling: touch;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
  }
`
export const ContainerLogo = styled.div`
  display: ${props => (props.sideNavExpanded ? 'none' : 'flex')};
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  flex: 0 0 300px;
  grid-area: logo;
  background-color: var(--black);
  @media (max-width: 1120px) {
    display: none;
  }
`

export const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  max-width: 100%;
  max-height: 100%;
`
