import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import { Input, Textarea, Select, ImageInput } from '../../../components/Form'

import { Header } from '../../../components/Main'

import Thumbnail from '../../../components/Thumbnail'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [payments, setPayments] = useState({})
  const formRef = useRef(null)
  const thumbnail = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getPayments = useCallback(async () => {
    const response = await api.get(`/payments/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
      thumbnail.current.SetThumbnail(response.data.thumbnail)

      formRef.current.setFieldValue('metodo', {
        value: response.data.metodo,
        label: response.data.titulo
      })
      setPayments(response.data)
    }
  }, [id])

  useEffect(() => {
    if (type === 'edit') {
      getPayments()
    }
  }, [type, getPayments])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  async function handleSubmit(data) {
    setLoading(true)
    try {
      let schema

      if (type === 'edit') {
        schema = Yup.object().shape({
          titulo: Yup.string().required('Titulo obrigatório'),
          descricao: Yup.string().required('Descrição obrigatório'),
          metodo: Yup.string().nullable().required('Opção obrigatório')
        })
      } else {
        schema = Yup.object().shape({
          titulo: Yup.string().required('Titulo obrigatório'),
          descricao: Yup.string().required('Descrição obrigatório'),
          image: Yup.string().required('Imagem obrigatório'),
          metodo: Yup.string().nullable().required('Opção obrigatório')
        })
      }

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { titulo, descricao, metodo, image } = data

      const payment = new FormData()

      payment.append('image', image)
      payment.append('titulo', titulo)
      payment.append('descricao', descricao)
      payment.append('metodo', metodo)

      if (type === 'edit') {
        const response = await api.put(`payments/${payments.id}`, payment)

        thumbnail.current.SetThumbnail(response.data.thumbnail)

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/payments', payment)

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Titulo</LabelInput>
            <Input type="text" name="titulo" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Descrição</LabelInput>
            <Textarea name="descricao" rows="8" cols="50"></Textarea>
          </InputGroup>
          <InputGroup>
            <LabelInput>Tipo</LabelInput>
            <Select
              name="metodo"
              options={[
                { value: 'multibanco', label: 'Multibanco' },
                { value: 'mbway', label: 'MB WAY' },
                { value: 'transferencia', label: 'Transferência Bancária' }
              ]}
              placeholder="Selecione o tipo de metodo"
              styles={customStyles}
            />
          </InputGroup>

          <Thumbnail ref={thumbnail} />

          <ImageInput name="image" accept="image/*" />

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
