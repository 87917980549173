import 'react-confirm-alert/src/react-confirm-alert.css'

import { Provider } from 'react-redux'

import { Router } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'

import { PersistGate } from 'redux-persist/integration/react'

import Routes from './routes'

import history from './routes/history'

import { store, persistor } from './store'

import GlobalStyle from './styles/global'

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
          </Router>
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
