import React, { useEffect, useRef, Fragment } from 'react'

import { useField } from '@unform/core'

import { Container, InputGroup, Titulo, Error } from './styles'

export default function Radio({ name, options }) {
  const inputRefs = useRef([])
  const { fieldName, registerField, defaultValue, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      getValue(refs) {
        const checked = refs.find(ref => ref.checked)

        return checked ? checked.value : null
      },
      setValue(refs, value) {
        const item = refs.find(ref => ref.value === value)

        if (item) {
          item.checked = true
        }
      }
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container>
        {options.map((option, index) => (
          <Fragment key={option.id}>
            <InputGroup>
              <input
                ref={elRef => (inputRefs.current[index] = elRef)}
                type="radio"
                name={fieldName}
                value={option.id}
                defaultChecked={defaultValue === option.id}
              />

              <Titulo>{option.label}</Titulo>
              <p>{option.descricao}</p>
            </InputGroup>
          </Fragment>
        ))}
      </Container>
      <Error>{error && <span>{error}</span>}</Error>
    </>
  )
}
