import styled from 'styled-components'

export const Container = styled.div`
  div.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: var(--box-background);
    box-shadow: 0 20px 75px rgb(0 0 0 / 23%);
    border-radius: 5px;
    color: #fff;

    h1 {
      color: var(--orange);
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;
    }

    p {
      color: var(--primary);
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 15px;
    }

    button {
      width: 160px;
      padding: 10px;
      border: 1px solid #fff;
      margin: 10px;
      cursor: pointer;
      background: none;
      color: #fff;
      font-size: 14px;
    }
  }
`
