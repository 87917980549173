import 'react-toastify/dist/ReactToastify.css'
import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    font: 400 14px Roboto, sans-serif;
    font-size: 14px;
    background: var(--black);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;

    div.overlay-custom-class-name {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      background: rgba(0, 0, 0, 0.9);
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      -ms-align-items: center;
      align-items: center;
      opacity: 0;
      -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
      animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    }
  }

  a {
    text-decoration: none;
  }

  input,
  button,
  textarea {
    font: 400 14px Roboto, sans-serif;
    background-color:transparent;
    border:none;
    border: 1px solid var(--black);
    transition: border 0.2s ease 0s;
  }

  input:focus{
    border-color: var(--orange);
  }

  textarea:focus{
    border-color: var(--orange);
  }

  button {
    cursor: pointer;
    border:none;
    outline:none
  }

  :root {
    --primary: #ffffff;
    --black: rgb(18, 18, 20);
    --red: #d80001;
    --red-dark-mode: #c5211c;
    --green-dark-mode: #075E54;
    --golden: #997018;
    --orange: #f26910;
    --border-gray-light: #d9dde5;
    --background-blue-light: #4b69b7;

    --box-background: rgb(32, 32, 36);

    --shadow-black-02: rgba(2, 8, 22, 0.2);
    --shadow-black-19: rgba(2, 8, 22, 0.19);

    --shadow-white-02:  rgba(255, 255, 255, 0.2);
    --shadow-white-19:  rgba(255, 255, 255, 0.19);

    --dark-text-color:  rgb(168, 168, 179);

    --white-text-color: #595f6f;

    --hover-gray: rgb(41, 41, 46);

    --gray-light: #4a4a4d;

    --scroll-track: rgb(41, 41, 46);
  }
`
