import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : 'unset')};
  background-color: ${props =>
    props.background ? props.background : 'var(--orange)'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => (props.radius ? `${props.radius}px` : '5px')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  margin-right: 10px;
  border: ${props => (props.border ? props.border : 'unset')};
  &:last-child {
    margin-right: 0;
  }
`
export const Button1 = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 15px;
  color: ${props => (props.color ? props.color : 'var(--primary)')};
  font-size: ${props => (props.size ? `${props.size}px` : '16px')};
  font-weight: 500;
  white-space: nowrap;
  text-transform: ${props => props.transform && css`uppercase`};

  > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div.icon_before_icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div.icon_before_icon > svg {
    margin-right: 5px;
  }
`
