import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--black);
  width: 100%;
  height: 100%;
  max-width: 350px;
  max-height: 150px;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  > audio {
    width: 100%;
    max-width: 350px;
    max-height: 150px;
  }
`
