import React, { useEffect, useRef } from 'react'

import { useField } from '@unform/core'

import { Container } from './styles'

export default function Textarea({ name, ...rest }) {
  const textareaRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value'
    })
  }, [fieldName, registerField])
  return (
    <Container>
      <textarea ref={textareaRef} defaultValue={defaultValue} {...rest} />
      {error && <span>{error}</span>}
    </Container>
  )
}
