import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 150px;
  border: 2px dashed var(--dark-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
`
export const Preview = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  align-items: center;
  justify-content: center;
  > audio {
    margin-left: 15px;
    z-index: 10;
  }
`
export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    font-size: 15px;
    color: var(--dark-text-color);
    font-weight: 400;
    margin-top: 10px;
  }

  > input {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 5;
    cursor: pointer;
  }
  @media (max-width: 868px) {
    > span {
      display: none;
    }
  }
`
export const Error = styled.span`
  display: inline-block;
  color: #fa2b56;
  font-size: 13px;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 15px;
`
