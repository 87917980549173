export const Types = {
  SEARCH_TERM: '@search/SEARCH_TERM'
}

const INITIAL_STATE = {
  searchTermValue: ''
}

export default function search(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SEARCH_TERM:
      return { ...state, searchTermValue: action.term }
    default:
      return state
  }
}

export function searchTerm(term) {
  return {
    type: Types.SEARCH_TERM,
    term
  }
}
