import { useState, useCallback, useEffect, useLayoutEffect } from 'react'

import { confirmAlert } from 'react-confirm-alert'

import { MdEdit, MdClose } from 'react-icons/md'

import { useSelector } from 'react-redux'

import { parseISO } from 'date-fns'

import Loading from '../../../components/Loading'

import { Header } from '../../../components/Main'

import List from '../../../components/Main/List'

import { Confirm } from '../../../components/Modal'

import history from '../../../routes/history'

import api from '../../../services/api'

function Compras(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { path } = props.match

  const { searchTermValue } = useSelector(state => state.search)

  const getCompras = useCallback(async () => {
    const response = await api.get('/compras-list')

    setData(() => {
      return response.data.filter(element => {
        return String(element.id).includes(searchTermValue)
      })
    })
  }, [searchTermValue])

  const handleClickDelete = useCallback(
    async item => {
      const { id } = item

      await api.delete(`/compras/${id}`)

      const newData = data.filter(data => data.id !== id)

      setData(newData)
    },
    [data]
  )

  const handleDelete = useCallback(
    data => {
      confirmAlert({
        overlayClassName: 'overlay-custom-class-name',
        customUI: function confirmDelete({ onClose }) {
          return (
            <Confirm
              onClose={onClose}
              handleClickDelete={() => handleClickDelete(data)}
            />
          )
        }
      })
    },
    [handleClickDelete]
  )

  useEffect(() => {
    getCompras()
  }, [getCompras])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return <Loading />
  }

  // const sortAscending = () => {
  //   const sortedData = [...data].sort((a, b) => b.id - a.id)

  //   setData(sortedData)
  // }
  // const sortDescending = () => {
  //   const sortedData = data.sort((a, b) => b - a)
  //   setData(sortedData)
  // }
  return (
    <>
      <Header title="Compras" newButton={false} path={`${path}/new`} />
      <List>
        <thead>
          <tr>
            <th>Nº Pedido</th>
            <th>Aluno</th>
            <th>Data</th>
            <th>Valor</th>
            <th>Status</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => (
            <tr key={data.id}>
              <td data-label="Nº Pedido">
                <div>{data.id}</div>
              </td>
              <td data-label="Aluno">
                <div>{data.user.nome}</div>
              </td>
              <td data-label="Data">
                <div className="produto_link">
                  {Intl.DateTimeFormat('pt-PT', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit'
                  }).format(parseISO(data.created_at))}
                </div>
              </td>
              <td data-label="Valor">
                {Intl.NumberFormat('pt-PT', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(data.total)}
              </td>
              <td data-label="Status">
                <div className={`${data.status ? 'active' : 'inactive'}`}>
                  {data.status ? 'Pago' : 'Pendente'}
                </div>
              </td>
              <td data-label="Opções">
                <div className="container_opcoes">
                  <div
                    onClick={() => history.push(`/compras/edit/${data.id}`)}
                    className="opcoes"
                  >
                    <MdEdit size={21} color="var(--orange)" />
                  </div>
                  <div onClick={() => handleDelete(data)} className="opcoes">
                    <MdClose size={21} color="var(--orange)" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </List>
    </>
  )
}

export default Compras
