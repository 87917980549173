import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
`

export const LoginContainer = styled.div`
  background-color: var(--black);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    color: var(--primary);
    font-size: 48px;
    font-weight: 700;
  }
`
export const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  max-width: 100%;
  max-height: 100%;

  @media (max-width: 1120px) {
    width: 200px;
  }
`

export const ContainerForm = styled.div`
  background-color: var(--box-background);
  border-radius: 5px;
  margin-top: 40px;

  form {
    margin: 30px;
    display: flex;
    flex-direction: column;
  }
  form .form-group {
    position: relative;
    margin-bottom: 20px;
    span {
      display: inline-block;
      color: var(--red);
      font-size: 13px;
      font-weight: 500;
      padding-top: 7px;
    }
  }
  form .form-group input {
    width: 300px;
    height: 50px;
    background-color: var(--black);
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    color: var(--primary);
    &::placeholder {
      color: var(--dark-text-color);
    }
  }
  button.button_login_facebook {
    width: 100%;
    background-color: var(--background-blue-light);
    border-radius: 5px;
    margin-top: 15px;
    padding: 15px;
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    i {
      margin-right: 15px;
    }
  }
`
