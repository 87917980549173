import React, { useRef, useEffect, useCallback, useState } from 'react'

import { FiUpload } from 'react-icons/fi'

import { useField } from '@unform/core'

import { Container, Preview, Image, Error } from './styles'

export default function AudioInput({ name, ...rest }) {
  const inputRef = useRef(null)
  const { fieldName, registerField, defaultValue, error } = useField(name)
  const [preview, setPreview] = useState(defaultValue)
  const handlePreview = useCallback(e => {
    const file = e.target.files?.[0]
    if (!file) {
      setPreview(null)
      return
    }
    const previewURL = URL.createObjectURL(file)
    setPreview(previewURL)
  }, [])
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = ''
        setPreview(null)
      },
      setValue(_, value) {
        setPreview(value)
      }
    })
  }, [fieldName, registerField])
  return (
    <>
      <Container>
        {preview && (
          <Preview>
            <audio controls>
              <source src={preview} type="audio/ogg" />
            </audio>
          </Preview>
        )}
        <Image>
          <input
            type="file"
            ref={inputRef}
            onChange={handlePreview}
            {...rest}
          />
          <FiUpload size={30} color="var(--orange)" />
          <span>Upload de áudio</span>
        </Image>
      </Container>
      {error && <Error>{error}</Error>}
    </>
  )
}
