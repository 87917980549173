import styled from 'styled-components'

export const Container = styled.div``

export const ContainerFormPerfil = styled.div`
  @media (max-width: 825px) {
    margin: 0;
    form {
      margin-top: 50px;
    }
  }
`
export const Table = styled.table`
  width: 100%;
  border-spacing: 0px 8px;
  margin-top: -8px;

  .inactive {
    background-color: var(--red-dark-mode);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active {
    background-color: var(--green-dark-mode);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 825px) {
    tbody {
      background-color: var(--box-background);
      border-radius: 5px;
      padding: 32px 32px 24px 32px;
    }
  }
  thead th {
    color: var(--dark-text-color);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    padding-left: 15px;
    padding-bottom: 5px;
  }
  thead th:first-child {
    padding-left: 15px;
  }
  tbody tr {
    border-radius: 5px;
    background-color: var(--hover-gray);
  }
  tbody tr td:first-child {
    border-radius: 5px 0px 0px 5px;
  }
  tbody tr td:last-child {
    border-radius: 0px 5px 5px 0px;
    padding-bottom: 16px;
  }
  tbody tr td {
    color: var(--primary);
    font-size: 15px;
    padding: 16px;
  }
  tbody tr td > div.container_opcoes {
    display: flex;
  }
  tbody tr td div.container_opcoes > div.opcoes {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    border-radius: 5px;
    padding: 0;
    width: 50px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  tbody tr td div.container_opcoes > div.opcoes:last-child {
    margin-right: 0;
  }

  tbody tr td > div.opcoes {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    border-radius: 5px;
    padding: 0;
    width: 50px;
    height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }
  tbody tr td > div.opcoes:hover {
    cursor: pointer;
  }
  @media (max-width: 825px) {
    border-spacing: 0px;
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 5px;
    }
    tbody tr + tr {
      margin-top: 8px;
    }
    tbody tr td + td {
      margin-top: 0.75em;
    }
    tbody tr td {
      padding: 0px;
    }
    tbody tr td:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    tbody tr td:last-child {
      border-radius: 0px 5px 5px 0px;
    }
    tbody tr td::before {
      content: attr(data-label);
      display: block;
      font-size: 13px;
      color: rgb(115, 115, 128);
      margin-bottom: 0.25em;
    }
  }
`
