import styled from 'styled-components'

export const ContainerFilters = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    > div {
      margin-bottom: 10px;
      &:last-child {
        margin-right: 10px;
      }
    }
  }
`
