import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import {
  Radio,
  Select,
  Textarea,
  CheckboxInput
} from '../../../components/Form'

import { Header } from '../../../components/Main'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [notificacoes, setNotificacoes] = useState({})
  const formRef = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getNotificacao = useCallback(async () => {
    const response = await api.get(`/notifications/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
      formRef.current.setFieldValue('read', String(response.data.read))
      setNotificacoes(response.data)
    }
  }, [id])

  const getUsers = useCallback(async () => {
    const response = await api.get('/users')

    setUsers(response.data)
  }, [])

  const getSelectedOption = useCallback(() => {
    const option = users.find(user => user.id === notificacoes.user_id)

    if (option) {
      formRef.current.setFieldValue('user_id', {
        value: option.id,
        label: option.nome
      })
    }
  }, [users, notificacoes])

  useEffect(() => {
    getUsers()
    if (type === 'edit') {
      getNotificacao()
    }
  }, [type, getNotificacao, getUsers])

  useEffect(() => {
    if (type === 'edit') {
      getSelectedOption()
    }
  }, [type, getSelectedOption])

  const customStyles = {
    control: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      background: 'var(--black)',
      borderColor: 'transparent',
      borderRadius: 5,
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'none'
      }
    }),
    valueContainer: base => ({
      ...base,
      height: '100%',
      maxHeight: 50,
      color: 'var(--primary)'
    }),
    placeholder: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'var(--black)' : 'var(--black)',
      color: 'var(--primary)'
    }),
    singleValue: base => ({
      ...base,
      color: 'var(--primary)'
    }),
    menuList: base => ({
      ...base,
      backgroundColor: 'var(--black)',
      opacity: '0.5'
    })
  }

  const serializeUsers = users.map(user => user.id)

  const checkboxOptions = [
    { id: 'all_users', value: 'all_users', label: 'Todos' }
  ]

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        mensagem: Yup.string().required('Mensagem obrigatório'),
        type: Yup.string().nullable().required('Opção obrigatório'),
        user_id: Yup.array().nullable().required('Opção obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { mensagem, type, user_id, all_users } = data

      let users_id = []

      if (all_users.length > 0) {
        users_id = serializeUsers
      } else if (user_id.length > 0) {
        users_id = user_id
      }

      if (type === 'edit') {
        const response = await api.put(`notifications/${id}`, {
          mensagem,
          type,
          users_id
        })

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/notifications', {
          mensagem,
          type,
          users_id
        })

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Mensagem</LabelInput>
            <Textarea name="mensagem" rows="8" cols="50"></Textarea>
          </InputGroup>

          <InputGroup>
            <LabelInput>Tipo de Notificação</LabelInput>
            <Select
              name="type"
              options={[
                { value: 'updates', label: 'Actualizações' },
                { value: 'info', label: 'Informações' }
              ]}
              placeholder="Selecione o tipo de notificação"
              styles={customStyles}
            />
          </InputGroup>

          <CheckboxInput name="all_users" options={checkboxOptions} />

          <InputGroup>
            <LabelInput>Utilizador</LabelInput>
            <Select
              name="user_id"
              options={users.map(user => {
                return { value: user.id, label: user.nome }
              })}
              isMulti
              placeholder="Selecione o utilizador"
              styles={customStyles}
            />
          </InputGroup>

          <LabelInput>Lida</LabelInput>
          <Radio
            name="read"
            options={[
              { id: 1, label: 'Sim' },
              { id: 0, label: 'Não' }
            ]}
          />

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
