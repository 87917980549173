import { useCallback } from 'react'

import { FiMenu } from 'react-icons/fi'

import { useDispatch, useSelector } from 'react-redux'

import Button from '../../components/Buttons/Button'

import history from '../../routes/history'

import { logout } from '../../services/auth'

import { logoutUser } from '../../store/ducks/auth'

import { sideNavExpand } from '../../store/ducks/sidebar'

import { Container } from './styles'

function Header() {
  const dispatch = useDispatch()

  const { data: user } = useSelector(state => state.auth)
  const { sideNavExpanded } = useSelector(state => state.sidebar)

  const handleLogoutIn = useCallback(() => {
    logout()

    dispatch(logoutUser())

    history.push('/')
  }, [dispatch])

  const handleSideNavExpand = useCallback(() => {
    dispatch(sideNavExpand(!sideNavExpanded))
  }, [sideNavExpanded, dispatch])

  let sobrenome

  const nome = user?.nome.split(' ')[0]
  const qtdnome = user?.nome.split(' ').length

  if (qtdnome > 1) {
    sobrenome = user?.nome.split(' ')[qtdnome - 1]
  }

  return (
    <Container>
      <div onClick={handleSideNavExpand} className="menu">
        <FiMenu size={21} color="var(--primary)" />
      </div>
      <div>
        <div className="user_name">
          {nome} {sobrenome}
        </div>
        <Button
          onClick={handleLogoutIn}
          type="button"
          width="50"
          height="30"
          title="Sair"
        />
      </div>
    </Container>
  )
}

export default Header
