import { useState, useCallback, useEffect, useLayoutEffect } from 'react'

import { confirmAlert } from 'react-confirm-alert'

import { MdEdit, MdClose } from 'react-icons/md'

import { useSelector } from 'react-redux'

import Loading from '../../../components/Loading'

import { Header } from '../../../components/Main'

import List from '../../../components/Main/List'

import { Confirm } from '../../../components/Modal'

import history from '../../../routes/history'

import api from '../../../services/api'

import removerAcentos from '../../../utils/removerAcentos'

function Modulos(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const { path } = props.match

  const { searchTermValue } = useSelector(state => state.search)

  const getModulos = useCallback(async () => {
    const response = await api.get('/modulos')

    setData(() => {
      return response.data.filter(element => {
        return removerAcentos(element.nome.toLowerCase()).includes(
          searchTermValue.toLowerCase()
        )
      })
    })
  }, [searchTermValue])

  const handleClickDelete = useCallback(
    async item => {
      const { id } = item

      await api.delete(`/modulos/${id}`)

      const newData = data.filter(data => data.id !== id)

      setData(newData)
    },
    [data]
  )

  const handleDelete = useCallback(
    data => {
      confirmAlert({
        overlayClassName: 'overlay-custom-class-name',
        customUI: function confirmDelete({ onClose }) {
          return (
            <Confirm
              onClose={onClose}
              handleClickDelete={() => handleClickDelete(data)}
            />
          )
        }
      })
    },
    [handleClickDelete]
  )

  useEffect(() => {
    getModulos()
  }, [getModulos])

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header title="Módulos" path={`${path}/new`} />
      <List>
        <thead>
          <tr>
            <th>ID</th>
            <th>Produto</th>
            <th>Nome</th>
            <th>Slug</th>
            <th>Preço</th>
            <th>Data de Criação</th>
            <th>Pago</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {data.map(data => (
            <tr key={data.id}>
              <td data-label="ID">
                <div>{data.id}</div>
              </td>
              <td data-label="Produto">
                <div>{data.curso.titulo}</div>
              </td>
              <td data-label="Nome">
                <div className="produto_link">{data.nome}</div>
              </td>
              <td data-label="Slug">{data.slug}</td>
              <td data-label="Preço">
                {Intl.NumberFormat('pt-PT', {
                  style: 'currency',
                  currency: 'EUR'
                }).format(data.preco)}
              </td>
              <td data-label="Data de Criação">
                <div>{data.created_at}</div>
              </td>
              <td data-label="Pago">
                <div className={`${data.pago ? 'active' : 'inactive'}`}>
                  {data.pago ? 'Sim' : 'Não'}
                </div>
              </td>
              <td data-label="Opções">
                <div className="container_opcoes">
                  <div
                    onClick={() => history.push(`/modules/edit/${data.id}`)}
                    className="opcoes"
                  >
                    <MdEdit size={21} color="var(--orange)" />
                  </div>
                  <div onClick={() => handleDelete(data)} className="opcoes">
                    <MdClose size={21} color="var(--orange)" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </List>
    </>
  )
}

export default Modulos
