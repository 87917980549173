import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState
} from 'react'

import { Container } from './styles'

function Audio({ source: afwf }, ref) {
  const [source, setSource] = useState('')

  const SetAudio = useCallback(source => {
    setSource(source)
  }, [])

  useImperativeHandle(ref, () => ({
    SetAudio
  }))

  return (
    <>
      {source && (
        <Container>
          <audio controls>
            <source src={source} type="audio/ogg" />
          </audio>
        </Container>
      )}
    </>
  )
}

export default forwardRef(Audio)
