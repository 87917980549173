import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => (props.options ? '30px' : '80px')};
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: ${props => (props.options ? '30px' : '50px')};
  }
`
export const Title = styled.h1`
  font-size: 25px;
  color: var(--primary);
  font-weight: 700;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`
export const ContainerBack = styled.div`
  display: ${props => (props.options ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-left: 40px;
  cursor: pointer;
  > span {
    font-size: 15px;
    color: var(--primary);
    font-weight: 400;
    margin-left: 5px;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`
export const Options = styled.div`
  display: ${props => (props.options ? 'none' : 'flex')};
  /* visibility: ${props => (props.options ? 'hidden' : 'visibile')}; */
  > form {
    display: flex;
    align-items: center;
    justify-content: center;
    > input {
      background-color: var(--primary);
      margin-right: 15px;
    }
    > div.buttens_header_mobile {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    > form {
      flex-direction: column;
      align-items: flex-start;
      > input {
        margin-bottom: 15px;
      }
      > div {
        margin-bottom: 15px;
      }
      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
`
