import styled from 'styled-components'

export const Container = styled.nav`
  flex: ${props => (props.sideNavExpanded ? ' 0 0 60px;' : ' 0 0 300px;')};
  grid-area: nav;
  background-color: var(--box-background);
  -webkit-overflow-scrolling: touch;

  transition: width 0.5s ease;

  overflow-y: ${props => (props.sideNavExpanded ? 'unset' : 'auto')};

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--gray-light);
  }

  > ul {
    list-style: none;
    > li {
      &:hover {
        width: 100%;
        background-color: var(--hover-gray);
      }
      > a {
        display: flex;
        align-items: center;
        justify-content: ${props =>
          props.sideNavExpanded ? 'center' : 'unset'};
        padding: ${props =>
          props.sideNavExpanded ? ' 12px 0 12px 0' : '12px 30px 12px 30px'};
        cursor: pointer;
        > div {
          display: ${props => (props.sideNavExpanded ? 'none' : 'block')};
          font-size: 13px;
          color: var(--primary);
          margin-left: 10px;
          text-transform: uppercase;
        }
      }
    }
  }
  .sidebar_heading {
    visibility: ${props => (props.sideNavExpanded ? 'hidden' : 'visible')};
    padding: 30px 30px 10px 30px;
    margin: 10px 0 0;
    color: var(--dark-text-color);
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  @media (max-width: 1120px) {
    flex: 0 0 60px;
    overflow-y: unset;
    > ul {
      list-style: none;
      > li {
        &:hover {
          width: 100%;
          background-color: var(--hover-gray);
        }
        > a {
          justify-content: center;
          cursor: pointer;
          padding: 12px 0 12px 0;
          > div {
            display: none;
          }
        }
      }
    }
    .sidebar_heading {
      visibility: hidden;
    }
  }
`
