import styled from 'styled-components'

export const Container = styled.header`
  display: flex;
  flex-basis: 100%;
  grid-area: header;
  height: 70px;
  background-color: var(--box-background);
  /* box-shadow: rgb(18, 18, 20) 0px 1rem 2rem; */
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  > a {
    font-size: 16px;
    color: var(--black);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > a {
      font-size: 16px;
      color: var(--orange);
    }
  }
  .user_name {
    font-size: 16px;
    color: var(--primary);
    margin-right: 15px;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`
