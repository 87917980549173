import styled from 'styled-components'

export const Container = styled.div`
  textarea {
    width: 100%;
    background-color: var(--black);
    border-radius: 5px;
    color: #000;
    padding-left: 15px;
    font-weight: 400;
    font-size: 14px;
    color: var(--primary);

    padding-top: 20px;
    ::placeholder {
      color: var(--primary);
      font-size: 14px;
      font-weight: 400;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`
