import { useCallback, useEffect, useRef, useState } from 'react'

import ReactLoading from 'react-loading'

import { useParams } from 'react-router-dom'

import { toast } from 'react-toastify'

import { Form as FormPacks } from '@unform/web'

import * as Yup from 'yup'

import Button from '../../../components/Buttons/Button'

import { Input, Textarea } from '../../../components/Form'

import { Header } from '../../../components/Main'

import history from '../../../routes/history'

import api from '../../../services/api'

import { Container, InputGroup, LabelInput } from './styles'

function Form(props) {
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null)
  const { id } = useParams()

  const { type, formTitle } = props

  const getPermisson = useCallback(async () => {
    const response = await api.get(`/permissions/${id}`)
    if (response.data) {
      formRef.current.setData(response.data)
    }
  }, [id])

  useEffect(() => {
    if (type === 'edit') {
      getPermisson()
    }
  }, [type, getPermisson])

  async function handleSubmit(data) {
    setLoading(true)
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório')
      })

      await schema.validate(data, {
        abortEarly: false
      })

      formRef.current.setErrors({})

      const { name, description, slug } = data

      if (type === 'edit') {
        const response = await api.put(`permissions/${id}`, {
          name,
          description,
          slug
        })

        if (response.data) {
          setLoading(false)
          toast.success('Dados actualizados com sucesso!', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      } else {
        await api.post('/permissions', { name, description, slug })

        setLoading(false)

        toast.success('Registo criado com sucesso!', {
          position: toast.POSITION.TOP_RIGHT
        })

        history.goBack()
      }
    } catch (err) {
      if (err.response) {
        setLoading(false)
        toast.error('Ocorreu um erro, tente novamente!', {
          position: toast.POSITION.TOP_RIGHT
        })
      }

      if (err instanceof Yup.ValidationError) {
        const validationErrors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Header title={formTitle} options="false" />
      <Container>
        <FormPacks ref={formRef} onSubmit={handleSubmit}>
          <InputGroup>
            <LabelInput>Titulo</LabelInput>
            <Input type="text" name="name" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Slug</LabelInput>
            <Input type="text" name="slug" />
          </InputGroup>
          <InputGroup>
            <LabelInput>Descrição</LabelInput>
            <Textarea name="description" rows="8" cols="50"></Textarea>
          </InputGroup>

          <Button
            width={100}
            height={35}
            margin=" 25px 5px 0 5px;"
            type="submit"
            title="Guardar"
            loading={
              loading && (
                <ReactLoading type="spin" color="#fff" height={20} width={20} />
              )
            }
          />
        </FormPacks>
      </Container>
    </>
  )
}

export default Form
