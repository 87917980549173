import React from 'react'

import { Route, Redirect } from 'react-router-dom'

import { getToken } from '../services/auth'

const RouteWrapper = ({ component: Component, isPrivate, ...rest }) => {
  const isAuthenticated = getToken()

  if (!isAuthenticated && isPrivate) {
    return <Redirect to="/" />
  }

  if (isAuthenticated && !isPrivate) {
    return <Redirect to="/dashboard" />
  }

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default RouteWrapper
